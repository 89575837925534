import { Label, Input, Select, Textarea, Radio, Checkbox, Slider, Box, Button } from "theme-ui";
import image from "../../../../content/pages/C\u0103r\u021Bile-mele/coperta-buna.png";
import * as React from 'react';
export default {
  Label,
  Input,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
  Box,
  Button,
  image,
  React
};